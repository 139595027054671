<template>
  <v-simple-table class="mb-4">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left" v-for="(title, index) in titles" :key="index">
            {{ title }}
          </th>
        </tr>
      </thead>
      <!-- renders batch as list -->
      <tbody v-if="render === 'batch'">
        <tr v-for="row in data" :key="row.id">
          <td>{{ row.name }}</td>
          <td>{{ row.date_created }}</td>
          <td>{{ row.provider }}</td>
          <td>{{ row.claims.length }}</td>
          <td>{{ row.claims.length }}</td>
          <td>
            <v-progress-linear
              class="rounded border"
              color="green darken-2"
              background-color="white"
              height="25"
              :value="row.claims.length"
              reactive
            ></v-progress-linear>
          </td>
          <td>
            <v-btn
              append
              :to="`${row.id}/claims`"
              color="black white--text"
              :disabled="!row.claims"
              small
              class="text-capitalize"
              >View Claims</v-btn
            >
          </td>
        </tr>
      </tbody>
      <!-- renders claims for clerk -->
      <tbody v-else-if="render === 'clerkClaims'">
        <tr v-for="(row, index) in data" :key="index">
          <td>{{ row.first_name }} {{ row.last_name }}</td>
          <td>{{ row.date_of_birth }}</td>
          <td>{{ row.unique_id }}</td>
          <td>{{ formatDate(new Date(row.date_created)) }}</td>
          <td>
            <v-btn
              text
              small
              :to="`/claims-processing/view/batches/${batch_id}/claims/${row.id}/update`"
            >
              <v-icon small>mdi-pen</v-icon>
            </v-btn>
          </td>
          <td>
            <v-btn
              text
              small
              class="text-capitalize font-weight-bold"
              @click="() => handleDelete(row.id)"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
      <!-- renders claims as list -->
      <tbody v-else>
        <tr v-for="(row, index) in data" :key="index">
          <td>{{ row.first_name }} {{ row.last_name }}</td>
          <td>{{ row.date_of_birth }}</td>
          <td>{{ row.unique_id }}</td>
          <td>{{ formatDate(new Date(row.date_created)) }}</td>
          <td>{{ row.vetted ? "Approved" : "Not Approved" }}</td>
          <td style="max-width: 150px" class="text-truncate">
            {{ row.reason === "" ? "-----" : row.reason }}
          </td>
          <td>
            <v-btn
              append
              :to="`${row.id}`"
              color="green darken-4 white--text"
              small
              class="text-capitalize font-weight-bold"
            >
              <span v-if="row.reason === ''">Approve</span>
              <span v-else>Preview</span>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    render: {
      type: String,
      required: true,
      default() {
        return "batch";
      },
    },
    titles: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: false,
    },
  },
  methods: {
    formatDate(date) {
      let monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      let day = date.getDate();
      let monthIndex = date.getMonth();
      let year = date.getFullYear();

      return day + " " + monthNames[monthIndex] + " " + year;
    },
    async handleDelete(id) {
      await this.deleteClaim({ data: id });
      // update batch list in vuex
      await this.fetchBatches();
    },
    ...mapActions({
      deleteClaim: "claimModule/deleteClaim",
      fetchBatches: "claimModule/fetchBatches",
    }),
  },
  computed: {
    batch_id() {
      return this.$route.params.batch_id;
    },
  },
};
</script>

<style scoped>
a:hover {
  text-decoration: none;
}
</style>
